<template>
  <div class="vehicle-models">
    <button
      class="button field is-info is-light"
      @click="isCardModalActive = true"
    >
      <b-icon icon="plus"></b-icon>
      <span>Novo modelo</span>
    </button>
    <b-table
      :data="allVehicleModels"
      ref="table"
      paginated
      per-page="10"
      :striped="true"
      :debounce-search="1000"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column
        field="model"
        label="Modelo"
        :searchable="true"
        sortable
        v-slot="props"
        >{{ props.row.model }}</b-table-column
      >

      <b-table-column
        field="brand"
        label="Marca"
        :searchable="true"
        sortable
        v-slot="props"
        >{{ props.row.brand }}</b-table-column
      >

      <b-table-column
        field="door_quantity"
        label="Quantidade de portas"
        :searchable="true"
        sortable
        v-slot="props"
        >{{ props.row.door_quantity }}</b-table-column
      >

      <b-table-column
        field="power"
        label="Potência"
        :searchable="true"
        sortable
        v-slot="props"
        >{{ props.row.power }}</b-table-column
      >

      <b-table-column v-slot="props" label="Opções">
        <button
          class="button is-small is-light"
          @click.prevent="
            onEdit(props.row)
            isCardModalActive = true
          "
        >
          <b-icon icon="pen" size="is-small"></b-icon>
        </button>
        <button
          class="button is-small is-danger"
          @click.prevent="onDelete(props.row)"
        >
          <b-icon icon="delete" size="is-small"></b-icon>
        </button>
      </b-table-column>
    </b-table>

    <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field label="Modelo *">
              <b-input required v-model="model.model"></b-input>
            </b-field>

            <b-field label="Marca *">
              <b-input required v-model="model.brand"></b-input>
            </b-field>

            <b-field label="Quantidade de portas *">
              <b-input
                type="number"
                required
                v-model="model.door_quantity"
              ></b-input>
            </b-field>

            <b-field label="Potência *">
              <b-input type="power" required v-model="model.power"></b-input>
            </b-field>

            <b-button type="button field is-info" @click="save" class="mt-10"
              >Salvar</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import service from '../features/vehicle-models/store/service'
import VehicleModel from '../models/vehicle-model'

export default {
  name: 'VehicleModels',
  data() {
    return {
      model: VehicleModel.model,
      isCardModalActive: false,
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('vehicleModels', ['allVehicleModels']),
  },
  methods: {
    ...mapActions('vehicleModels', ['getAllVehicleModels']),
    async init() {
      await this.getAllVehicleModels()
    },
    onEdit(item) {
      this.model = { ...item }
    },
    async save() {
      const vehicleModel = this.model

      try {
        const save = vehicleModel.id
          ? await service.updateVehicleModel(vehicleModel)
          : await service.saveVehicleModel(vehicleModel)
        await this.$success('Modelo de Veículo')
        location.reload(true)
      } catch (error) {
        this.$error(
          error.response.data.message || error.response.data[0].message
        )
      }
    },
    async onDelete(id) {
      try {
        const result = await this.$ask('Você deseja remover o modelo de veículo?')

        if (result.isConfirmed) {
          await service.deleteVehicleModel(id)

          await this.$delete('Modelo de Veículo')
          location.reload(true)
        }
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
  },
}
</script>

<style scoped></style>
